<script setup lang="ts">
import { RouterView } from 'vue-router';
const title = ref("MAY SOCHIC");
useHead({
    meta: [{ content: title }],
    titleTemplate: (titleChunk) => {
        return titleChunk
            ? `MAY SOCHIC`
            : "MAY SOCHIC";
    },
});
</script>

<template>
    <v-locale-provider>
        <v-app>
            <LayoutFullMain />
            <v-main>
                <v-container fluid class="page-wrapper">
                    <div class="maxWidth">
                        <RouterView />
                    </div>

                </v-container>
                <v-col class="text-center mt-2">
                    
                    <p class="text-muted">Conçu et développé par <a flat variant="text" href="#"
                            target="_blank" class="pl-1 text-primary">SAS METIC</a></p>
                </v-col>
            </v-main>
        </v-app>
    </v-locale-provider>
</template>
