import {
    FileDescriptionIcon,
    BellRinging2Icon, InfoCircleIcon, SosIcon,
    LayoutDashboardIcon, UsersIcon, CalendarTimeIcon, NoteIcon, CalendarEventIcon
} from 'vue-tabler-icons';

let loggerType: any
if (process.client) {
    let logger = JSON.parse(localStorage.getItem("logger"));
    loggerType = logger.user_type
    console.log(loggerType)
}

export interface menu {
    header?: string;
    title?: string;
    icon?: any;
    to?: string;
    chip?: string;
    chipColor?: string;
    chipVariant?: string;
    chipIcon?: string;
    children?: menu[];
    disabled?: boolean;
    type?: string;
    subCaption?: string;
}

const sidebarItem: menu[] = [
    {
        title: 'TABLEAU DE BORD',
        icon: LayoutDashboardIcon,
        to: '/dashboard'
    },
    {
        title: 'ACTUALITES',
        icon: FileDescriptionIcon,
        to: '/actualites'
    },
    {
        title: 'AGENDA',
        icon: CalendarEventIcon,
        to: '/agenda',
        
    },
    {
        title: 'SIGNALEMENTS',
        icon: InfoCircleIcon,
        to: '/signalement'
    },
    {
        title: 'NOTIFICATIONS',
        icon: InfoCircleIcon,
        to: '/notifications'
    },
  
    {
        title: 'UTILISATEURS',
        icon: UsersIcon,
        to: '/utilisateurs'
    },
];

 


export default sidebarItem;
